:root {
  --base-delay: -59.85s;
  --delay-step: 0.15s;
}

.img-fluid{
  width: 100%;
}

@font-face {
    font-family: GROOVY;
    src: url("assets/fonts/SuperMagic.ttf");
    font-display: swap;
}

@font-face {
    font-family: Albert-Sans;
    src: url("assets/fonts/AlbertSans-VariableFont_wght.ttf");
    font-display: swap;
}

.ComponentCont{
  width: 100vw;
  transform: translateX(-100%);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  transition: 1s transform ease-in-out;
}

.ComponentCont.slideLeft{
  transform: translateX(0);
}

.ComponentCont.slideRight{
  transform: translateX(-200%);
}

.BG,.BG1,.BG2{
  width: 100vw;
  position: relative;
  min-height: 812px;
  max-height: 812px;
  overflow: hidden;
}

.backButton{
  position: absolute;
  width: 80px!important;
  height: 210px; 
  padding-bottom: 2rem;
  border-left: 0;
  border-bottom: 0;
  border-color: black;
  border-width: 1px;
  background-color: #ff508a;
  top: -0.5%;
  right: 0;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 280%;
  font-family: albert-sans, sans-serif;
  text-shadow: 1px 1px black;
  text-align: end;
  color: #b2d6b5;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  transform: rotate(180deg);
}

.backButton2{
  position: absolute;
  width: 80px!important;
  height: 210px; 
  padding-top: 2rem;
  border-left: 0;
  border-top: 0;
  border-color: black;
  border-width: 1px;
  background-color: #ff508a;
  top: -0.5%;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 280%;
  font-family: albert-sans, sans-serif;
  text-shadow: 1px 1px black;
  text-align: end;
  color: #b2d6b5;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}


.BG1, .BG2{
  z-index: 1;
  transition: 2.5s transform ease-in-out;
}

.text{
  text-align: justify;
  font-family: Albert-sans, sans-serif;;
  line-height: 1.35;
  font-size: 200%;
}

.text-slide{
  text-align: justify;
  font-family: Albert-sans, sans-serif;;
}

.moduleWrapper{
  min-height: 812px;
  max-height: 812px;
}

.scroll-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.scroll-content {
  position: absolute;
  width: 100%;
  height: fit-content; 
  background-color: #f9efe8; 
  transition: transform 0.35s ease-out; 
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  background-color: black;
  z-index: 9999999;
}


.zIndex1{
position: relative;
z-index: 1;
}

.smooth-scroll {
will-change: transform;
transition: transform 1.5s ease-in-out;
}

@media (width >= 1700px) {
    .BG,.BG1,.BG2{
      min-height: 1001px;
      max-height: 1001px;
    }
}

@media (width >= 1520px) and (width <= 1700px) {
@media (height >= 1049px) {
  .BG,.BG1,.BG2.moduleWrapper{
    min-height: 1070px;
    max-height: 1070px;
  }
}
}

@media (width >= 1051px) {
  .layoutM{
      margin-top: 5vw;
  }

  @media (height <= 650px) {
    .BG,.BG1,.BG2,.moduleWrapper{
      min-height: 641px;
      max-height: 641px;
    }
  }
}

@media (width <= 769px) {
#videoSM{
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: absolute;
}
}

.bg-groovy{
  overflow: hidden;
}

.BODYCONT{
  position: relative;
  transition: 0.05s;
  left: 0;
  overflow: hidden;
}

.BODYCONT.hiddenLayout{
  transition: 0.05s left ease-in-out;
  left: 100%;
}

.heroCont{
  max-width: 100vw;
  width: 100%;
  height: 813px;
  overflow: hidden;
  transform-origin: top left;
  transition: transform 0.3s ease; 
}

.BGHero{
  width: 100vw;
  height: 812px;
  max-height: 812px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: seashell;
  position: relative;
  z-index: 9999;
  display: grid;
  grid-template: "area1 area2 area3 area4 area4 area13 area13 area5 area6" 20% "area1 area2 area3 area4 area4 area13 area13 area5 area6" 5% "area1 area7 area3 area4 area4 area13 area13 area5 area6" 5% "area1 area7 area3 area4 area4 area8 area8 area8 area6" 17.5% "area1 area7 area3 area4 area4 area8 area8 area8 area6" 5.5% "area12 area12 area10 area10 area11 area11 area11 area11 area11" 10% "area12 area12 area10 area10 area11 area11 area11 area11 area11" 3% "area12 area12 area10 area10 area11 area11 area11 area11 area11" 35% / 14.5% 17.15% 17.65% 4% 14.5% 4% 9.5% 13.7% 5%;
}
.area1 { grid-area: area1; }

.area2 {    
  grid-area: area2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.area3 { grid-area: area3;
  position: relative;
  scale:1.1;
  z-index: 1;
  display: flex;
  align-items: center;
}

.area4 { grid-area: area4; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
 }

.area5 { grid-area: area5;
  top: 0%;
  position: relative;
}

.area6 { grid-area: area6; 

}

.area7 { 
  grid-area: area7; 
  background-color: darkblue; 
  opacity: 0.5; 
}

.area8 {     
  grid-area: area8;
  display: flex;
  flex-direction: column;
  align-items: center;
} 

.area9 { 
  grid-area: area9;
   background-color: darkorchid; 
   opacity: 0.5;  
  }

.area10 { 
  grid-area: area10;     
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  padding: 10%; 
scale: 1.15;
top: -2%;}

.area11 {
   grid-area: area11;
   position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    scale: 1;
    z-index: 3;
}
.area12 { grid-area: area12;}


.area13{
  grid-area: area13;
}

.BG-COLOR{
  transform-origin: top left; 
  transition: transform 0.3s ease; 
}

.effect {
  transition: transform 0.1s ease;
}

.BGIMG{
  width: max-content;
  position: absolute;
  z-index: 0;
  top: -30vh;

}

  .BGHero::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(rgb(255 255 255 / 20%), transparent); 
}

  .window{
    background-image: url('/assets/images/hero/window.webp');
    height: 135%;
    width: 150%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    top: -8rem;
    left: -5rem;
  }

  .rainbow{
    position: absolute;
    top: -15%;
    left: 43%;
    z-index: 0;
    width: 40%;
  }

  .logoPositioner{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .phone{
    position: absolute;
    right: 12%;
    top: 0%;
    scale: 0.5;
    transition: scale 0.1s ease, left 1s ease, right 1s ease;
  }

  .audio-control{
    position: absolute;
    width: calc(10px + 5vw);
    height: calc(10px + 5vw);
    bottom: 10%;
    left: 2%;
    display: flex;
    z-index: 999;
  }

  .audio-control button{
    width:max-content;
    border: none;
    background:none;
  }

  .logo{
    width: 170px;
    position: relative;
    z-index: 1;
    margin-bottom: -15%;
  }

  .chairCont{
    position: absolute;
    left: 22rem;
    width: 100%;
    display: flex;
    top: 62%;
    align-items: flex-end;
    justify-content: center;
    scale: 1;
  }

  .chair{
    width: 95%;
    position: relative;
    z-index: 1;
  }

  .elementsContainer{
       position: relative;
       top: 5%;
       z-index: 2;
  }

  .tableTv{
    background-image: url('/assets/images/hero/Table.svg');
    width: 480px;
    height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    left: 2rem;
  }
  
  
  .robot{
    width: 13.02%; /* Ancho en porcentaje */
    height: 18.52%; /* Altura en porcentaje */
    position: absolute;
    left: 32%;
    top: 8%;
    z-index: 1;
  }

  .bus{
    position: absolute;
    bottom: 2rem;
    left: 30rem;
    scale: 1.15;
    z-index: 2;

  }

  .machine{
    position: absolute;
    top: -5rem;
    right: 5%;
    scale: 0.75;
    z-index: 2;
  }

  .turnTablet{
    position: absolute;
    top: -24%;
    z-index: 2;
    left: 2%;
    scale: 1.1;
  }
  
  .papers{
    z-index: 3;
  }

.appStore,.googlePlay{
  z-index: 3;
  width: 34%;
  position: relative;
  top: -16%;
}

.googlePlay{
  width: 30%;
  margin-bottom: 2%;
}

.Pencil{
  width: fit-content;
  position: relative;
  display: flex;
  left: 10rem;
  bottom: 3rem;
}

  @media (width >= 1720px){
    .moduleWrapper{
      min-height: 1001px;
      max-height: 1001px;
    }

    .heroCont{
      margin-bottom: 0!important;
      height: 1001px;
    } 

    .turnTablet{
      top: -30%;
      scale: 1.3;
    }

    .area11{
        scale: 0.975;
    }

    .appStore,.googlePlay{
      top: 30%;
    }

    .area3{
      scale: 1.35;
      align-items: center;
      justify-content: center;
    }

    .area4{
      position: relative;
      top: -18%;
    }

    .area8{
      align-items: center;
      margin-top: -25%;
      position: relative;
      left: -14%;
    }

    .rainbow{
      top: -21%;
      left: 40%;
      z-index: 0;
      width: 40%;
    }

    /* .papers{
      scale: 1.25;
      left: 15%;
      top: 8%;
      position: absolute;
    } */

/* 
        .robot{
      scale: 1.315;
    } */
    .BGIMG{
    scale: 1.1;
    top: -10%;
    }

    .hero{
      width: 300px;
      height: 200px;
      position: absolute;
      left: 32%;
      top: 3%;
    }

    .BGHero{
      max-height: 100vh;
      height: 100vh;
    }

    .chairCont{
      top: 65%;
      left: 20%;
      align-items: flex-end;
      justify-content: center;
      scale: 1.25;
    }

    .bus{
      left: 34%;
      scale: 1.3;
      bottom: 5rem;
    }

    .machine{
      scale: 0.9;
      top: -25%;
      right: 10%;
    }

    .elementsContainer{
      top: 0%;
      left: 15%;
      scale: 1.35;

    }

    .logo{
      scale: 1.5;
      margin-bottom: -50%
    }

    .phone{
      position: absolute;
      right: 17%;
      top: 2%; 
      scale: 0.7!important;
    }

    .area10{
      left: 8%;
      scale: 1.05;
    }
  }

  @media (width >= 1520px) and (width <= 1700px) {
    @media (height >= 1049px) {
      .heroCont{
        margin-bottom: 0%!important;
      }

      .BGIMG{
        top: 0;
      }

      .elementsContainer{
        scale: 1.35;
        top: 32%;
      }

      .window{
        height: 150%;
      }

      .area3{
        scale: 1.2;
        margin-left: 13%;
        margin-top: 8%;
      }

      .area6{
        scale: 1.45;
        position: relative;
        top: 12%;
      }

      .area10{
        top: 60%;
        scale: 1.7;
        z-index: 3;
      }

      .area11{
        scale: 1.15;
        z-index: 3;
        bottom: -2%;
      }

      .machine{
        right: 4vw !important;
    top: 20%;
      }

      .turnTablet{
        top: 5%;
    scale: 1.35;
      }

      .appStore, .googlePlay{
        top: 40%;
    right: 15%;
      }

      .chair{
        top: 60%;
      }

      .audio-control{
        bottom: -15%;
      }
    }

    @media (height >= 920px) and (height <= 1048px) {
      .chairCont{
        scale: 1.05!important;
        top: 62%!important;
        left: 25%!important;
      }

      .machine{
        right: calc(28rem + 5vw)!important;
      }

      .bus{
        position: absolute;
        bottom: 2rem;
        left: 35rem!important;
        scale: 1.35!important;
        z-index: 2;
      }

      .googlePlay{
        top: 28%;
      }

      .woman{
        position: relative;
    top: -16%;
    right: 2%;
      }

      .heroCont{
        margin-bottom: -17%!important;
      }
    }

    .chairCont{
      top: 65%;
      left: 20%;
      align-items: flex-end;
      justify-content: center;
      scale: 1;
    }

    .bus{
      position: absolute;
      bottom: 1rem;
      left: 28rem;
      scale: 1;
    }

    .logoPositioner{
      margin-left: 4rem;
    }

    .phone{
      scale: 0.5;
    }

   
  }

  @media (width >= 1439px) and (height >= 820px) {
    .bus{
      position: absolute;
      bottom: 2rem;
      left: 30rem;
      scale: 1.15;
      z-index: 2;
    }

    .heroCont{
      margin-bottom: -6.5%;
    }

    .logoPositioner{
      margin-left: 4rem;
    }

    .phone{
      scale: 0.5;
    }
  }

  @media (width <= 1400px) {
      .heroCont{
        width: 1399px;
        max-width: 1399px;
        height: 769px;
        max-height: 769px;
      }

      .bus{
        left: 30rem;
        scale: 1.1;
      }

      .chairCont{
        scale: 0.95;
        left: 21.5rem;
        top: 63%;
        z-index: 2;
      }

      .woman{
        position: relative;
        bottom: 14%;
        right: 3%;
        z-index: 1;
      }

      @media (height <= 650px) {
        .heroCont{
          height: 641px;
          max-height: 641px;
        }

        .elementsContainer{
          top: -5%;
          scale: 0.9;
        }

        .bus{
          position: absolute;
    bottom: 6rem;
    left: 29rem;
    scale: 0.8;
    z-index: 2;
    }

    /* .googlePlay{
      position: absolute;
      top: 24%;
      right: 19%;
    }
    .appStore{
      position: absolute;
      width: 8%;
      right: 18.5%;
      top: 40%;
    } */
    .chairCont{
      top: 53%;
    }

    .woman{
      position: relative;
      bottom: 9%;
      left: -4%;
    }

    .phone{
      right: 12%;
      top: -2%;
      scale: 0.45;
    }
      }
  }

  @media (width <= 1369px) {
   .area10{
    top: 2%;
    scale: 1.1;
    left: -4%;
   }

   .area3{
    align-items: center;
   }
  }

  @media (width <= 1290px){
    .robot{
    position: absolute;
    left: 35%;
    top: 5%;
    }

    .machine{
      right: 21rem;
    }

    .heroCont {
      transform-origin: top left; 
      transition: transform 0.3s ease;
    }
 
  }

  @media (width >= 800px) and (width <= 1050px) {
    .elementsContainer{
      top: -2%;
      right: 24%;
      z-index: 2;
      scale: 0.95;
    }

    .area3{
      scale: 0.85;
      top: -12%;
      right: 10%;
    }

      .area4{
        scale: 0.8;
        left: 10%;
        top: -10%;
        z-index: 1;
      }

      .area5{
        top: 0%;
        right: 35%;
        position: relative;
      }

      .machine{
        right: -2% !important;
        top: -6%;
        scale: 0.6;
      }

      .area10{
        top: 3%;
        left: -20%;
        scale: 1;
      }

      .area11{
        scale: 1.15;
        top: -6%;
        right: 4%;
        z-index: 3;
      }

      .turnTablet{
        position: absolute;
        top: 0%;
        z-index: 2;
        left: 4%;
        scale: 0.9;
      }

      .chair{
        scale: 0.95;
        width: 100%;
      }

      .googlePlay{
        width: 40%;
      }

      .appStore, .googlePlay{
        right: 30%;
        width: 42%;
        top: 5%;
      }
  }

  @media (width <= 769px) {
  .logo{
    z-index: 1;
  }

    .bgGroovy{
      min-width: 99vw;
      max-width: 100vw;
      overflow: hidden;
    }
  }
 
.lg-none{
    display: none!important;
  }

  .btn-slider .star {
    position: absolute;
    width: 20px;
    height: 20px;
    background: url('/assets/images/home/STAR.webp') no-repeat center center;
    background-size: contain;
    opacity: 0;
  }

  .btn-slider{
    background: url('/assets/images/home/BTN.svg') no-repeat center center;
    overflow: hidden;
    transition: transform 0.5s ease, filter 0.5s ease;
  }

  .btnText{
    font-family: GROOVY, sans-serif;
    font-size: 330%;
    margin-top: 10%;
    position: relative;
    top: -20%;
    color: #bcdabc;
    transform: matrix3d(1, 0, 1, 0, 0, 1.05, 0, -0.0025, 0, 0, 1, 0, 0, 0, 0, 1);
    letter-spacing: -2.35px;
  }

  .btnText span {
    display: inline-block;
    -webkit-text-stroke: 2px black; 
    text-stroke: 2px black;
    text-shadow: 3px 5px black;
    transform: translateY(-5%);
  }
 

  .btn-slider img {
    display: block;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
  }
  
  .btn-slider:hover {
    transform: scale(1.2); /* Efecto de expansión */
  }

  .btn-slider:hover .btnText{
  letter-spacing: -0.5px;
  transition:  letter-spacing 0.25s ease-in-out;
}
  
  .btn-slider .star.top-right {
    top: -10px;
    right: -10px;
    width: 40px;
    height: 40px;
  }

  .btn-slider .star.top-left {
    top: -10px;
    left: -10px;
    width: 40px;
    height: 40px;
  }
  
  
  .btn-slider .star.bottom-left {
    bottom: -10px;
    left: -10px;
  }

  .btn-slider .star.bottom-right {
    bottom: -10px;
    right: -10px;
  }
  
  @keyframes shine {
    0%, 100% {
      transform: scale(1) rotate(0deg);
      opacity: 0;
    }

    50% {
      transform: scale(1.5) rotate(160deg);
      opacity: 1;
    }
  }

  @keyframes shine2 {
    0%, 100% {
        transform: scale(1) rotate(0deg);
        opacity: 0;
      }

      50% {
        transform: scale(1.5) rotate(-160deg);
        opacity: 1;
      }
  }
  
  @keyframes stretch {
    0%, 100% {
      transform: scale(1);
    }

    50% {
       transform: matrix(0.75, 0.025, 0.135, 1, 9, 0);
    }
  }
  
  
  .btn-slider:hover .star.top-right {
    animation: shine 1.25s infinite;
  }

  .btn-slider:hover .star.top-left {
    animation: shine 1.25s infinite;
  }

  .btn-slider:hover .star.bottom-left {
    animation: shine2 1.15s infinite;
  }

  .btn-slider:hover .star.bottom-right {
    animation: shine2 1.15s infinite;
  }

  .btnText span:nth-child(4),
  .btnText span:nth-child(5) {
  transform: matrix(1.15, 0, 0, 1.2, 0, 0);
  margin: 0 1px;
  transition: transform 0.35s ease-in-out;
  
  }
  
  .btnText span:nth-child(3),
  .btnText span:nth-child(6) {
  transform: matrix(1, 0, 0, 1.2, 0, 0);
  margin: 0 1px;
  transition: transform 0.35s ease-in-out;
  
  }
  
  .btnText span:nth-child(2),
  .btnText span:nth-child(7) {
  transform: matrix(1, 0, 0, 1.2, 0, 0);
  margin: 0 1px;
  transition: transform 0.35s ease-in-out;
  
  }
  
  .btnText span:nth-child(1),
  .btnText span:nth-child(8) {
  transform: matrix(1, 0, 0, 1.2, 0, 0);
  margin: 0 1px;
  transition: transform 0.35s ease-in-out;
  
  }

  .btnText span:nth-child(9), .btnText span:nth-child(10), .btnText span:nth-child(11) {
    transform: matrix(1, 0, 0, 1, 0, 2);
  transition: transform 0.35s ease-in-out;
  }

  .btn-slider .btnText span:nth-child(10),
  .btn-slider .btnText span:nth-child(11){
    transition: transform 0.25s ease-in-out;
  }

  .btn-slider:hover .btnText span:nth-child(2),
  .btn-slider:hover .btnText span:nth-child(7){
  transform: matrix(1, 0, 0, 1.5, 0, 0);
  margin: 0 -5px;
  transition: transform 0.25s ease-in-out;
  position: relative;
  top: -5px;
  }

  .btn-slider:hover .btnText span:nth-child(4),
  .btn-slider:hover .btnText span:nth-child(5) {
    transform: matrix(1.3, 0, 0, 1.7, 0, 0);
    margin: 0 2px;
    transition: transform 0.25s ease-in-out;
    position: relative;
    top: -5px;
  }

  .btn-slider:hover  .btnText span:nth-child(3),
  .btn-slider:hover  .btnText span:nth-child(6) {
    transform: matrix(1, 0, 0, 1.95, 0, 0);
    margin: 0 2px;
    position: relative;
    top: -5.5px;
    transition: transform 0.25s ease-in-out;
  }

  .btn-slider:hover .btnText span:nth-child(1){
    transform :matrix(0.9, 0, 0.5, 1.15, 0, 0);
    margin: 0 2px;
    transition: transform 0.25s ease
  }

  .btn-slider:hover .btnText span:nth-child(7){
    transform :matrix(0.9, 0, -0.5, 1.6, 0, 0);
    margin: 0 2px;
    transition: transform 0.25s ease-in-out;
  }

  .btn-slider:hover .btnText span:nth-child(8){
    transform :matrix(0.9, 0, -0.5, 1.15, 0, 0);
    margin: 0;
    transition: transform 0.25s ease-in-out;
  }


  .btn-slider:hover .btnText span:nth-child(9){
    transform: matrix(1, 0.2, 0, 1, 0, 0) rotate(6deg);
    transition: transform 0.25s ease-in-out;
    margin: 0 2px;
  }

  .btn-slider:hover .btnText span:nth-child(10){
    transform: matrix(1, 0, 0, 1.25, 1, 0);
    transition: transform 0.25s ease-in-out;
  }

  .btn-slider:hover .btnText span:nth-child(11){
    transform: matrix(1, 0, 0, 1.6, 0, 0);
    margin: 0 2px;
    transition: transform 0.25s ease-in-out;
  }
  


  .btn-slider.second:hover .btnText span:nth-child(1) {
    transform: matrix(0.9, 0, 0.15, 1.15, 0, 0);
    margin: 0 2px;
  }
  
  .btn-slider.second:hover .btnText span:nth-child(2),
  .btn-slider.second:hover .btnText span:nth-child(7) {
    transform: matrix(1, 0, 0, 1.5, 0, 0);
    margin: 0 -5px;
    top: -5px;
  }
  
  .btn-slider.second:hover .btnText span:nth-child(3),
  .btn-slider.second:hover .btnText span:nth-child(6) {
    transform: matrix(1, 0, 0, 1.45, 1, 5);
    margin: 0 2px;
    top: -5.5px;
  }
  
  .btn-slider.second:hover .btnText span:nth-child(4),
  .btn-slider.second:hover .btnText span:nth-child(5) {
    transform: matrix(1.3, 0, 0, 1.5, 0, 6);
    margin: 0 2px;
    top: -5px;
  }
  
  .btn-slider.second:hover .btnText span:nth-child(9) {
    transform: matrix(1, 0.2, 0, 1, 0, 0) rotate(6deg);
    margin: 0 2px;
  }
  
  .btn-slider.second:hover .btnText span:nth-child(10) {
    transform: matrix(1, 0, 0, 1.25, 1, 0);
  }
  
  .btn-slider.second:hover .btnText span:nth-child(11) {
    transform: matrix(1, 0, 0, 1.6, 0, 0);
    margin: 0 2px;
  }

  @media (width <= 1124px) {
    /* .lg-none{ display: inherit!important; } */
    .md-none{
      display: none;
    }
  }

  @media (width >= 800px) and (width <= 1050px) {
    .btnText{
      font-size: 280%;
    }
    
  }

  @media (width <= 769px) {
    .sm-none{
      display: none;
    }

    .lg-none{
      display: inherit!important;
    }
  }